<template>
  <div class="dropdown-component">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data(){
    return{
      dropdown: {
        count: 0,
        active: null
      }
    }
  },
  provide(){
    return {dropdown: this.dropdown}
  }
}
</script>

<style lang="scss">

</style>