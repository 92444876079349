<template>
  <header class="header">
    <div class="header__wrapper">
      <div class="header__container _container">
        <div class="header__logo">
          <router-link to="/"
            ><img src="../assets/images/logo/logo.png" alt=""
          /></router-link>
        </div>
        <div class="header__body">
          <div class="header__top">
            <div class="header__menu" :class="{ _active: menuIsOpen }">
              <h2 class="header__sub-title">
                Меню
                <SelectComponent1
                  :type="'lang'"
                  :options="languages"
                  @select="getLang"
                ></SelectComponent1>
              </h2>
              <ul class="header__list">
                <li v-for="link in headerLinks" :key="link.id">
                  <template
                    v-if="
                      link.slug === 'wholesale-department' ||
                      link.slug === 'news'
                    "
                    ><router-link
                      @click="menuIsOpen = false"
                      :to="'/' + link.slug"
                      class="footer__link"
                    >
                      {{ $t(`nav.${link.slug}`) }}
                    </router-link>
                  </template>
                  <template v-else
                    ><router-link
                      @click="menuIsOpen = false"
                      :to="{ name: 'About', params: { slug: link.slug } }"
                      class="footer__link"
                    >
                      {{ $t(`nav.${link.slug}`) }}
                    </router-link>
                  </template>
                </li>
                <!--                <li>-->
                <!--                  <router-link :to="{ name: 'News', params: { id: news[0].id } }" class="footer__link">-->
                <!--                    Новости-->
                <!--                  </router-link>-->
                <!--                </li>-->
              </ul>
            </div>
            <div class="header__about">
              <div class="header__contacts">
                <div class="header__contact">
                  <div class="header__phone">
                    <span
                      ><img src="../assets/icons/header/phone-call.svg" alt=""
                    /></span>
                    <a :href="`tel:${contacts.data[0].phone_numbers[0].phone}`">{{
                      formatPhoneNumber(contacts.data[0].phone_numbers[0].phone)
                    }}</a>
                  </div>
                  <div class="header__small-text">
                    {{ $t("labels.retail") }}
                  </div>
                </div>
                <div class="header__contact">
                  <div class="header__phone">
                    <span
                      ><img src="../assets/icons/header/phone-call.svg" alt=""
                    /></span>
                    <a :href="`tel:${contacts.data[1].phone_numbers[0].phone}`">{{
                      formatPhoneNumber(contacts.data[1].phone_numbers[0].phone)
                    }}</a>
                  </div>
                  <div class="header__small-text">
                    {{ $t("labels.wholesale") }}
                  </div>
                </div>
              </div>
              <div class="header__lang">
                <SelectComponent1
                  :type="'lang'"
                  :options="languages"
                  @select="getLang"
                ></SelectComponent1>
              </div>
            </div>
          </div>
          <div class="header__main">
            <div class="header__group">
              <div
                class="header__catalog header-catalog"
                @mouseenter="catalogIsOpen = true"
                @mouseleave="catalogIsOpen = false"
              >
                <div class="header-catalog__btn main-btn" ref="catalog-btn">
                  <div
                    class="header-catalog__icon header-icon"
                    :class="{ _active: catalogIsOpen }"
                  >
                    <span class="header-icon__first"></span>
                    <span class="header-icon__second"></span>
                    <span class="header-icon__third"></span>
                    <span class="header-icon__fourth"></span>
                  </div>
                  {{ $t("catalog") }}
                </div>
                <transition name="category">
                  <div
                    class="header-catalog__menu"
                    :class="{ _active: catalogIsOpen }"
                    v-if="catalogIsOpen"
                    v-click-out-side="hideCatalog"
                  >
                    <div class="header-catalog__title">
                      {{ $t("categories") }}
                    </div>
                    <div class="header-catalog__content">
                      <ul class="header-catalog__list">
                        <DropdownComponent>
                          <li v-for="(category, idx) in categories" :key="idx">
                            <DropdownMenu
                              class="product-dropdown"
                              :item="category"
                            >
                            </DropdownMenu>
                          </li>
                        </DropdownComponent>
                      </ul>
                    </div>
                  </div>
                </transition>
              </div>
              <div class="header__mobile__search">
                <div class="header__search">
                  <input
                    type="text"
                    class="header__input"
                    v-model="searchText"
                    :placeholder="$t('search')"
                    required
                    v-on:keyup.enter="searchProduct"
                  />
                  <div id="errorMsg" class="header__error" v-if="errMes">
                    {{ errMes }}
                  </div>
                  <button style="margin-right: 10px" @click="searchProduct">
                    <img src="../assets/icons/header/searchM.png" alt="" />
                  </button>
                </div>
              </div>
              <div class="header__search header__search_first">
                <input
                  type="text"
                  class="header__input"
                  v-model="searchText"
                  :placeholder="$t('search')"
                  v-on:keyup.enter="searchProduct"
                  required
                />
                <button
                  class="button header__search-btn"
                  @click="searchProduct"
                >
                  {{ $t("search") }}
                </button>
              </div>
            </div>
            <div class="header__burger">
              <div
                @click="menuIsOpen = !menuIsOpen"
                :class="{ 'menu-open': menuIsOpen }"
                class="icon-menu"
              >
                <span></span>
              </div>
            </div>
            <div class="header__actions">
              <div class="header__action header__action_mobile">
                <router-link to="/" class="header__btn">
                  <span class="header__icon"
                    ><img src="../assets/icons/header/home.svg" alt=""
                  /></span>
                </router-link>
                <router-link to="/">{{ $t("main") }}</router-link>
              </div>
              <div
                class="header__action header__action_mobile"
                @click="showCatal"
              >
                <div class="header__btn">
                  <span class="header__icon"
                    ><img src="../assets/icons/header/catalog.svg" alt=""
                  /></span>
                </div>
                <div>{{ $t("catalog") }}</div>
              </div>
              <div class="header__action">
                <router-link to="/shopping-cart" class="header__btn">
                  <span class="header__icon"
                    ><img src="../assets/icons/header/card.svg" alt="" />
                    <span class="header__icon_cart" v-if="cartCount > 0">{{
                      cartCount
                    }}</span></span
                  >
                </router-link>
                <router-link to="/shopping-cart">{{
                  $t("basket")
                }}</router-link>
              </div>
              <div class="header__action">
                <router-link to="/favorites" class="header__btn">
                  <span class="header__icon"
                    ><img src="../assets/icons/card/heart.svg" alt="" />
                    <span class="header__icon_cart" v-if="favoriteCount > 0">{{
                      favoriteCount
                    }}</span></span
                  >
                </router-link>
                <router-link to="/favorites">{{ $t("favorites") }}</router-link>
              </div>
              <div class="header__action" style="cursor: pointer;">
                <div class="header__btn" @click="login">
                  <span class="header__icon"
                    ><img src="../assets/icons/header/account.svg" alt=""
                  /></span>
                </div>

                <span v-if="getAuth">{{ $t("profile") }}</span>
                <span v-else>{{ $t("auth") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cMobile" v-if="showCatalog">
      <div class="cMobile__content">
        <button class="cMobile__content-title">{{ $t("categories") }}</button>
        <div
          class="cMobile__content__block"
          v-for="(category, index) in categories"
          :key="index"
        >
          <button
            v-if="category.sub_categories.length"
            class="cMobile__content__block-title"
            @click="toast($event.target)"
          >
            <router-link
              @click="selectCategory(category.id, category.id)"
              :to="{ name: 'Catalog', params: { slug: category.slug } }"
              class="dropdown__link"
              >{{ category.title }}
            </router-link>
          </button>
          <div
            class="cMobile__content__block-subs"
            :style="'display: none;'"
            v-if="category.sub_categories.length"
          >
            <div
              class="cMobile__content__block-elems"
              v-for="(subCategory, idx) in category.sub_categories"
              :key="idx"
            >
              <router-link
                @click="selectCategory(subCategory.id, category.id)"
                :to="{ name: 'Catalog', params: { slug: subCategory.slug } }"
                class="dropdown__link"
                >{{ subCategory.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import DropdownMenu from "@/components/DropdownMenu";
import SelectComponent1 from "@/components/SelectComponent1";
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import DropdownComponent from "@/components/DropdownComponent";
import axios from "axios";

export default {
  name: "Header",
  components: { DropdownComponent, SelectComponent1, DropdownMenu },
  directives: {
    clickOutSide,
  },
  data() {
    return {
      searchText: "",
      catalogIsOpen: false,
      menuIsOpen: false,
      errMes: "",
      showCatalog: false,
      headerLinks: [
        { name: "О компании", slug: "about" },
        { name: "Партнеры", slug: "cooperation" },
        { name: "Новости", slug: "news" },
        // { name: "Оптовый отдел", slug: "wholesale-department" },
        { name: "Установка", slug: "loyalty" },
        { name: "Доставка", slug: "delivery" },
      ],
      languages: [
        { id: 1, value: "ru", img: require("../assets/images/langs/ru.png") },
        { id: 1, value: "kz", img: require("../assets/images/langs/kz.png") },
        // { id: 1, value: "en", img: require("../assets/images/langs/en.png") },
      ],
    };
  },
  computed: {
    ...mapState(["categories", "contacts", "news", "favorites"]),
    ...mapGetters(["getAuth", "favoriteCount", "cartCount"]),
  },
  methods: {
    ...mapMutations(["SET_CATEGORY", "SET_CATEGORY1", "UPDATE_LANG"]),
    selectCategory(category, main) {
      this.SET_CATEGORY1(main);
      this.SET_CATEGORY(category);
      this.showCatalog = false
    },
    ...mapActions([
      "requestUser",
      "fetchCategories",
      "fetchContacts",
      "checkAuth",
    ]),
    showCatal() {
      this.showCatalog = !this.showCatalog;
      (this.menuIsOpen = false), document.body.classList.toggle("hidde");
      window.scrollTo(0, 0);
    },
    getLang(option) {
      this.$i18n.locale = option.value;
      this.UPDATE_LANG(option.value);
      axios.defaults.params = {
        lang: this.$store.state.lang,
      };
      //or in file components
      this.$forceUpdate();
      location.reload();
    },
    toast(event) {
      event.classList.toggle("active");
      var panel = event.nextElementSibling;
      if (panel?.style.display == "flex" || panel?.style.display == "") {
        panel.style.display = "none";
      } else {
        panel.style.display = "flex";
      }
    },
    hideCatalog(e) {
      const catalogBtn = this.$refs["catalog-btn"];
      if (!(e.target == catalogBtn || catalogBtn.contains(e.target))) {
        this.catalogIsOpen = false;
      }
    },
    login() {
      if (this.getAuth) {
        this.$router.push("/personal-account");
      } else this.$router.push("/login");
    },
    formatPhoneNumber(tel) {
      let cleaned = ("" + tel).replace(/\D/g, "");
      let format = cleaned.replace(/^8/, 7);
      let regex = /^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})/;

      if (format.match(regex)) {
        return format.replace(regex, "+$1 ($2) $3 $4-$5");
      }
    },
    searchProduct() {
      if (this.searchText !== "") {
        this.$router.push({
          path: "/results",
          query: { text: `${this.searchText}` },
        });
        this.searchText = "";
      } else {
        this.errMes = "Заполните это поле";
      }
    },
  },
  created() {
    this.checkAuth();
    this.fetchContacts();
    this.fetchCategories();
  },
  mounted() {
    this.requestUser();
  },
  watch: {
    "$route.query.idsub"() {
      this.catalogIsOpen = false
    },
    "$route.path": function () {
      (this.catalogIsOpen = false), (this.showCatalog = false);
      document.body.classList.remove("hidde");
    },
    $route() {
      this.menuIsOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header__mobile__search {
  display: none;
  @media (max-width: $mobile + px) {
    display: block;
  }
}
.cMobile {
  display: none;
  overflow-y: scroll;
  width: 100vw;
  z-index: 4;
  position: fixed;
  background: white;
  top: 70px !important;
  bottom: 60px;
  &__content {
    width: 100%;
    padding: 30px 20px;
    &-title {
      font-size: 20px;
    }
    &__block {
      margin-top: 20px;
      &-title {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &:after {
          width: 16px;
          height: 27px;
          content: "";
          background: url("../assets/icons/bot.png") no-repeat center;
          color: #777;
          font-weight: bold;
          float: right;
          margin-left: 5px;
        }
      }
      .active:after {
        background: url("../assets/icons/up.png") no-repeat center;
      }
      &-subs {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 10px 15px 0 15px;
      }
    }
  }
  @media (max-width: $tablet + px) {
    display: flex;
  }
}
ul li {
  list-style: none;
}
.header {
  @media (max-width: $mobile + px) {
    height: 85px;
  }
  &__error {
    padding-right: 10px;
    font-size: 12px;
    color: #dea623;
  }
  &__wrapper {
    box-shadow: 0px 4px 3px rgba(125, 125, 125, 0.1);
    padding: 28px 0 9px 0;
    @media (max-width: $tablet + px) {
      padding: 20px 0 12px 0;
    }
    @media (max-width: $mobile + px) {
      padding: 12px 0;
      margin: 0 0 20px 0;
      position: fixed;
      top: 0;
      left: 0;
      justify-content: space-between;
      z-index: 21;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 37px;
    position: relative;
    @media (max-width: $tablet + px) {
      gap: 0 33px;
    }
    @media (max-width: $mobileSmall + px) {
      gap: 0 29px;
    }
  }

  &__bottom {
    @media (min-width: $mobile + px) {
      display: none;
    }
  }

  &__body {
    flex: 0 1 100%;
    @media (max-width: $mobile + px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__logo {
    img {
      width: 105px;
      margin-top: -40px;
      height: 56px;
      @media (max-width: $tablet + px) {
        width: 61px;
        height: 35px;
        margin-top: 0px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 40px;
        height: 25px;
        margin-top: 0px;
      }
    }
  }

  &__top {
    margin: 0 0 14px 0;
    @media (min-width: $tablet + px) {
      border-bottom: 1px solid #e9e9e9;
      padding: 0 0 17px 0;
    }
    @media (min-width: $mobileSmall + px) {
      display: flex;
      align-items: center;
      gap: 0 30px;
      justify-content: space-between;
      margin: 0 0 30px 0;
    }
    @media (max-width: $tablet + px) {
      justify-content: flex-end;
      margin: 0 0 25px 0;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0 20px;
    @media (min-width: $tablet + px) {
      justify-content: space-between;
      gap: 0 50px;
    }
    @media (min-width: $tablet + px) {
      gap: 0 25px;
    }
  }

  &__group {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 0 20px;
    @media (min-width: $tablet + px) {
      justify-content: space-between;
      gap: 0 50px;
    }
    @media (min-width: $tablet + px) {
      gap: 0 25px;
    }
    @media (min-width: $mobile + px) {
      position: relative;
    }
  }

  &__menu {
    flex: 0 1 656px;
    z-index: 2;
    @media (max-width: $tablet + px) {
      display: none;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0px 0px 10px 10px;
    }

    &._active {
      display: block;
      position: absolute;
      top: 125%;
      left: 20px;
      right: 20px;
      @media (max-width: $mobile + px) {
        left: 0;
        right: 0;
        width: 100%;
      }
    }
  }

  &__burger {
    @media (min-width: $tablet + px) {
      display: none;
    }
  }
  .select {
    @media (max-width: $tablet + px) {
      display: none;
    }
  }
  &__sub-title {
    .select {
      display: inline-block;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend .base-text-600;
    border-bottom: 1px solid #c2c2c2;
    padding: 12px 6px;
    @media (min-width: $tablet + px) {
      display: none;
    }

    @media (max-width: $tablet + px) {
      padding: 12px 15px;
    }
  }

  &__list {
    @media (min-width: $tablet + px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

@media (max-width: 1100px) {
  li {
    font-size: 13px;
  }
}

    @media (max-width: $tablet + px) {
      padding: 22px 6px 26.5px 15px;
      li {
        font-size: 17px;
      }
    }

    li {
      &:not(:last-child) {
        @media (max-width: $tablet + px) {
          margin: 0 0 25px 0;
        }
      }
    }
  }

  &__link {
    font-weight: 400;
    font-size: 16px;
    line-height: 118.75%;
    @media (max-width: $pc + px) {
      font-size: 14px;
    }
  }

  &__about {
    @media (min-width: $mobileSmall + px) {
      display: flex;
      align-items: center;
      gap: 0 34px;
    }
    @media (max-width: $pc + px) {
      gap: 0 20px;
    }
  }

  &__contacts {
    display: flex;
    align-items: center;
    gap: 0 28px;
    @media (max-width: $tablet + px) {
      justify-content: flex-end;
    }
    @media (max-width: $mobile + px) {
      display: none;
      gap: 0 10px;
    }
    @media (max-width: $mobileSmall + px) {
      gap: 0 10px;
    }
  }

  &__phone {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    white-space: nowrap;

    span {
      width: 10px;
      height: 10px;
      margin: 0 5px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 9.98px;
        height: 9.98px;
      }
    }
  }

  &__small-text {
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    color: #727272;
    @media (max-width: $mobileSmall + px) {
      display: none;
    }
  }

  &__lang {
    @media (max-width: $mobileSmall + px) {
      display: none;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 0 35px;
    @media (max-width: $mobile + px) {
      position: fixed;
      bottom: 0;
      left: 0;
      justify-content: space-between;
      z-index: 21;
      width: 100%;
      height: 60px;
      background: #ffffff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
      padding: 0 18px;
    }
    @media (max-width: $pc + px) {
      gap: 0 15px;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: #727272;

    &_mobile {
      @media (min-width: $mobile + px) {
        display: none;
      }
    }

    @media (max-width: $mobile + px) {
      font-size: 10px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: 0 auto 10px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: $mobile + px) {
      width: 24px;
      height: 24px;
      margin: 0 0 2px 0;
    }

    &_cart {
      position: absolute;
      top: -8px;
      right: -8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: red;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      color: #ffffff;
      font-size: 8px;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 1 742px;
    height: 46px;
    border: 1px solid #c2c2c2;
    border-radius: 10px;
    overflow: hidden;
    font-weight: 400;
    font-size: 14px;
    line-height: 121.42%;

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background: #d81921;
      height: 100%;
      padding: 0 26px;
      border-radius: 0px 10px 10px 0px;
    }

    &_first {
      @media (max-width: $mobile + px) {
        display: none;
      }
    }
  }

  &__input {
    width: 100%;
    background: #ffffff;
    padding: 15px;

    &::placeholder {
      color: #c2c2c2;
    }
  }

  &__select {
    option {
      width: 32px;
      height: 21px;
    }
  }
}

.header-catalog {
  &__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 48px;
    font-weight: 400;
    line-height: 100%;
    font-size: 15px;
    @media (max-width: $mobile + px) {
      padding: 0 10px;
      border-radius: 20px !important;
      height: 30px;
      font-size: 12px;
    }
  }

  &__menu {
    z-index: 21;
    position: absolute;
    left: 0;
    right: 0;
    top: 57px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 10px 10px;
    @media (max-width: $mobile + px) {
      top: 120%;
      left: 16px;
      right: 16px;
    }
  }

  &__title {
    @extend .base-text-600;
    border-bottom: 1px solid #c2c2c2;
    padding: 15px 23px 9px;
  }

  &__content {
    padding: 11px 0 27px;
    position: relative;
  }
  @media (max-width: $mobile + px) {
    display: none;
  }
}

.header-icon {
  display: flex;
  align-items: center;
  gap: 1.67px;
  flex-wrap: wrap;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease;
  cursor: pointer;

  span {
    display: inline-block;
    width: 9.17px;
    height: 9.17px;
    border: 1.3px solid #ffffff;
    border-radius: 30%;
    transition: all 0.3s ease;

    &:first-child,
    &:last-child {
      top: 20px;
    }
  }

  @media (max-width: $mobile + px) {
    width: 12px;
    height: 12px;
    gap: 1px;
    margin: 0 5px 0 0;
    span {
      width: 5px;
      height: 5px;
      border-width: 1px;
    }
  }

  &._active {
    gap: 0;

    span {
      border: none;
      width: 50%;
      height: 1.5px;
      border-radius: 0;
      background: #ffffff;
      @media (max-width: $mobile + px) {
        height: 1px;
      }
    }

    .header-icon__first {
      transform: translate(2px, 2px) rotate(45deg);
    }

    .header-icon__second {
      transform: translate(-2px, 2px) rotate(-45deg);
    }

    .header-icon__third {
      transform: translate(2px, -2px) rotate(-45deg);
    }

    .header-icon__fourth {
      transform: translate(-2px, -2px) rotate(45deg);
    }
  }
}

.icon-menu {
  display: none;
  @media (max-width: $tablet + px) {
    display: block;
    position: relative;
    min-width: 22px;
    min-height: 14px;
    width: 22px;
    height: 14px;
    cursor: pointer;
    z-index: 5;
    span,
    &::before,
    &::after {
      content: "";
      transition: all 0.35s ease-in-out;
      right: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      border-radius: 25px;
      background-color: #d81921;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    span {
      top: calc(50% - 1px);
    }
  }

  &.menu-open {
    span {
      transform: scale(0);
    }

    &::before {
      transform: rotate(45deg);
      top: calc(50% - 1px);
    }

    &::after {
      transform: rotate(-45deg);
      bottom: calc(50% - 1px);
    }
  }
}

.category-enter-active,
.category-leave-active {
  transition: opacity 0.3s;
}

.category-enter,
.category-leave-to {
  opacity: 0;
}
</style>