<template>
  <div class="dropdown" v-click-out-side="hideMenu">
    <div class="dropdown__container">
      <router-link
        @click="selectCategory(item.id)"
        :to="'/catalog/' + item.slug"
        class="dropdown__button"
        ref="dropdownBtn"
        @mouseenter="openMenu"
      >
        {{ item.title }}
        <span><img src="../assets/icons/header/arrow.svg" alt="" /></span>
      </router-link>
      <transition name="dropdown">
        <div class="dropdown__content" v-show="isOpen">
          <ul class="dropdown__list">
            <li v-for="(elem, i) in item.sub_categories" :key="i">
              <router-link
                @click="selectCategory(elem.id)"
                :to="'/catalog/' + item.slug + '?idsub=' + elem.id"
                class="dropdown__link"
                >{{ elem.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import { mapMutations } from "vuex";

export default {
  inject: ["dropdown"],
  directives: {
    clickOutSide,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      index: null,
    };
  },
  methods: {
    ...mapMutations(["SET_CATEGORY"]),
    selectCategory(category) {
      this.hideMenu();
      this.SET_CATEGORY(category);
    },
    hideMenu() {
      this.isOpen = false;
    },
    openMenu() {
      if (this.visible) {
        this.dropdown.active = null;
      } else {
        this.dropdown.active = this.index;
      }
    },
  },
  computed: {
    isOpen() {
      return this.index == this.dropdown.active;
    },
  },
  created() {
    this.index = this.dropdown.count++;
  },
};
</script>

<style lang="scss" scoped>
ul li {
  list-style: none;
}
.dropdown-enter-active,
.dropdown-leave-active {
  transition: opacity 0.5s;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
}

.dropdown {
  &__content {
    top: 0;
    @media (min-width: $tablet + px) {
      position: absolute;
      left: 406px;
      top: 26px;
    }
    @media (max-width: $pc + px) {
      left: 315px;
    }
    @media (max-width: $tablet + px) {
      padding: 13px 30px;
    }
  }

  &__button {
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    max-width: 382px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #202020;
    padding: 0 10px 0 23px;
    transition: all 0.3s ease 0s;
    text-align: left;

    &:hover {
      background: #f7f7f7;
    }

    @media (max-width: $pc + px) {
      max-width: 300px;
    }
    @media (max-width: $tablet + px) {
      max-width: 100%;
    }
    @media (max-width: $mobileSmall + px) {
      padding: 0 22px 0 7px;
    }

    span {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 10.54px;
        height: 6.25px;
      }
    }
  }

  &__list {
    li {
      &:not(:last-child) {
        margin: 0 0 15px 0;
        @media (max-width: $mobileSmall + px) {
          margin: 0 0 10px 0;
        }
      }
    }
  }

  &__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    transition: color 0.2s ease;

    &:hover {
      color: #d81921;
    }
  }
}
</style>
